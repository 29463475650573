import React from 'react';
import {
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Text,
  Input,
  Flex,
  Tag,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

export const CustomerIdFormControl = ({ register, errors, ...props }) => {
  return (
    <FormControl isInvalid={errors.customerId}>
      <FormLabel htmlFor="customerId">Customer Id</FormLabel>
      <Input
        {...props}
        id="customerId"
        type="text"
        placeholder="Customer Id"
        {...register('customerId')}
      />
      <FormErrorMessage>{errors?.customerId?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const CustomPincodeFormControl = ({
  controlId,
  label,
  placeholder,
  register,
  errors,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}{' '}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id={controlId}
        placeholder={placeholder}
        {...register(controlId, {
          required: 'This is required',
          pattern: {
            value: /^[1-9][0-9]{5}$/,
            message: 'Wrong pincode',
          },
        })}
      />
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export const CustomDeliveryPincodeFormControl = ({
  controlId,
  label,
  placeholder,
  register,
  errors,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}{' '}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id={controlId}
        placeholder={placeholder}
        {...register(controlId, {
          required: 'This is required',
          pattern: {
            value: /^[1-9][0-9]{5}$/,
            message: 'Wrong pincode',
          },
        })}
      />
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export const PaymentModeControl = ({
  controlId,
  label,
  control,
  errors,
  isCOD,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        name={controlId}
        control={control}
        defaultValue=""
        rules={{ required: 'This is required' }}
        render={({ field }) => (
          <Select
            id={controlId}
            placeholder={`Select ${label}`}
            onChange={e => {
              field.onChange(e);
            }}
            {...field}
          >
            <option value="Prepaid">Prepaid</option>
            {isCOD && <option value="COD">COD</option>}
            {/* {isToPay && <option value="ToPay">ToPay</option>} */}
          </Select>
        )}
      />
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};
export const FormTag = ({ tagText, ...props }) => {
  return (
    <Flex justifyContent={'center'} my={3} {...props}>
      <Tag
        textAlign={'center'}
        colorScheme="blackAlpha"
        px={5}
        py={2}
        letterSpacing={1.5}
        fontWeight={700}
        fontSize={25}
      >
        {tagText}
      </Tag>
    </Flex>
  );
};
