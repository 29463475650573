import React from 'react';
import {
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  TableCaption,
} from '@chakra-ui/react';
import AwbImg from '../Awb/AwbImg';
import { useSelector } from 'react-redux';
import {getB2bConfig} from '../../redux/slices/SettingsDetailsSlice';
const formatKey = key => {
  return key.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2');
};
const B2bCalculatorPriceCards = ({ calculatorDetails }) => {
  const b2bConfig=useSelector(getB2bConfig);
  const {servicePartners}=b2bConfig;
  const { priceList } = calculatorDetails || {};
  return priceList?.map((object, index) => {
    let { Base_Fright_Charge, Fuel_Surcharge, FM_Charge, Insurance,serviceProviderName,price_Per_Kg,pickupCode } =
      object || {};
    let baseFrightCharge =
      Number(Base_Fright_Charge) +
      Number(Fuel_Surcharge) +
      Number(FM_Charge) +
      Number(Insurance);
    let pickupZone = pickupCode?.split('_')[2];

    const newObject = {
      pickupCode: pickupCode,
      'pickup Zone': pickupZone,
      'Pickup Pincode': calculatorDetails?.pickUpPincode,
      'Drop Pincode': calculatorDetails?.deliveryPincode,
      'Base Fright Charge': baseFrightCharge,
      price_Per_Kg: object?.price_Per_Kg,
      ...object,
    };
    const keysToDelete = [
      'LM_Charge',
      'Base_Fright_Charge',
      'Fuel_Surcharge',
      'FM_Charge',
      'Insurance',
      'price_Per_Kg',
    ];
    keysToDelete.forEach(key => {
      delete newObject[key];
    });
    return (
      <TableContainer
        key={index}
        py={1}
        px={4}
        border={'1px solid '}
        borderColor={'blackAlpha.500'}
        rounded={'md'}
      >
        <Table size={'sm'} variant="simple">
          <TableCaption
            m={0}
            placement="top"
            fontSize={22}
            fontWeight={'bold'}
            textTransform={'capitalize'}
          >
            <AwbImg
              shipmentPartner={serviceProviderName}
              w="full"
              objectFit={'cover'}
              alt={`${serviceProviderName}-Courier Name`}
              loading="lazy"
              mx="auto"
              icon={servicePartners[serviceProviderName]?.icon}
            />
          </TableCaption>
          <Tbody>
            {Object.entries(newObject).map(([key, value]) => (
              <Tr
                key={key}
                bg={'inherit'}
                color={'inherit'}
                fontWeight={'inherit'}
              >
                <Td
                  p={1.5}
                  textTransform={'capitalize'}
                  fontWeight={'bold'}
                  borderRight={'1px solid '}
                  borderRightColor={'blackAlpha.100'}
                  fontSize={key === 'total' ? 18 : 15}
                >
                  {formatKey(key)}
                </Td>
                <Td
                  mx={'auto'}
                  p={1.5}
                  fontSize={key === 'total' ? 18 : 16}
                  fontWeight={key === 'total' && 'bold'}
                >
                  {key === 'total' ? value.toFixed(2) : value}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  });
};

export default B2bCalculatorPriceCards;
