const getRoleName=(role)=>{
  if(["admin","SADMIN"].includes(role)){
    return "admin"
  }else if(["OEL","OPM","BDM"].includes(role)){
    return "manager"
  }else if(["OPE"].includes(role)){
    return "ope"
  }
  else if(["BDE","BDA","CRS"].includes(role)){
    return "bde"
  }else if(["CSR"].includes(role)){
    return "agent"
  }else{
    return "agent"
  }
}
export function permissionCheck(role, elementName) {
  const componentManager = {
    admin: {
      leads: {
        visible: ['excel'],
      },
      allMerchant: {
        visible: ['sideBar'],
      },
      shippingPartner: {
        visible: ['servicebility'],
      },
      invoice:{
        disabled:['downloadInvoice']
      },
      weightDiscrepancy: {
        visible: ['uploadFile'],
      },
      remittance:{
        visible: ['notes'],
      }

    },
    manager: {
      allMerchant: {
        maskFields:[],// ['contact_details'],
        disabled: ['form_updation'],
      },
      shippingPartner: {
        disabled: ['addShippingButton'],
      },
    },

    ope: {
      merchant: {
        maskFields:[], //['contact_details'],
        visible: ['bank_details', 'kyc', 'price'],
        disabled: ['pay-bill'],
      },
      allMerchant: {
        maskFields:[],// ['contact_details'],
        disabled: ['form_updation'],
      },
      personal_details: {
        disabled: ['editButton'],
      },
      shippingPartner: {
        maskFields: ['email', 'pocNumber', 'pocEmail'],
        disabled: ['addShippingButton'],
      },
    },

    bde: {
      merchant: {
        maskFields:[], //['contact_details'],
        visible: ['bank_details', 'kyc', 'price'],
        disabled: ['pay-bill', 'shipment-action-button'],
      },
      allMerchant: {
        maskFields:[],// ['contact_details'],
        disabled: ['form_updation'],
      },
      personal_details: {
        disabled: ['editButton'],
      },
      shippingPartner: {
        maskFields: ['email', 'pocNumber', 'pocEmail'],
        disabled: ['addShippingButton'],
      },
    },

    agent: {
      merchant: {
        maskFields: ['contact_details'],
        visible: ['excel', 'bank_details', 'kyc', 'price'],
        disabled: ['pay-bill', 'shipment-action-button'],
      },
      allMerchant: {
        maskFields: ['contact_details'],
        disabled: ['form_updation'],
      },
      personal_details: {
        disabled: ['editButton'],
      },
      shippingPartner: {
        maskFields: ['email', 'pocNumber', 'pocEmail'],
        disabled: ['addShippingButton'],
      },
    },
  };

  const roleName=getRoleName(role);
  const permissions = componentManager[roleName]?.[elementName] || {
    visible: [],
    restrictedFields: [],
    maskFields: [],
    restrictedButtons: [],
    disabled: [],
  };

  return {
    isVisible: permissions?.visible || [],
    maskFieldList: permissions?.maskFields || [],
    isDisabled: permissions?.disabled || [],
    restrictedButtons: permissions?.restrictedButtons || [],
  };
}
