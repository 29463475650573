import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button, SimpleGrid, Box, Flex } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { B2C_CALCULATOR_SERVICEMODE } from '../../utils/utilityConstants';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
import { formatValue } from '../../utils/utilsFunction';
import {
  WeightFormControl,
  CodAmountFormControl,
  LengthFormControl,
  WidthFormControl,
  HeightFormControl,
  ServiceModeControl,
} from '../BookShipments/BookingFormField';
import {
  CustomDeliveryPincodeFormControl,
  CustomPincodeFormControl,
  PaymentModeControl,
  CustomerIdFormControl,
} from './CalculatorField';
import CalculatePage from '../../pages/Calculator.page';
const B2CcalculatorForm = () => {
  const loggedUser = useSelector(getLoggedUser);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  const height = useWatch({ control, name: 'height' });
  const width = useWatch({ control, name: 'width' });
  const length = useWatch({ control, name: 'length' });
  function onSubmit(values) {
    const params = {
      ...values,
      weight: formatValue(values?.weight),
      height: formatValue(values?.height),
      length: formatValue(values?.length),
      width: formatValue(values?.width),
      empId: loggedUser?.empId,
    };
    dispatch({
      type: 'CALCULATE_PRICE',
      payload: params,
    });
  }

  return (
    <Flex color="blackAlpha.800" gap={2} justifyContent={'flex-end'}>
      <CalculatePage
        flexBasis={'60%'}
        maxH="calc(100vh - 80px)"
        overflow={'auto'}
      />
      <form onSubmit={handleSubmit(onSubmit)} style={{ flexBasis: '40%' }}>
        <SimpleGrid columns={[1, 1, 1, 2]} spacing={2}>
          <CustomerIdFormControl
            controlId="customerId"
            label="Customer Id"
            register={register}
            errors={errors}
          />
          <ServiceModeControl
            controlId={'serviceMode'}
            label="Service Mode"
            errors={errors}
            control={control}
            options={B2C_CALCULATOR_SERVICEMODE}
          />
          <CustomPincodeFormControl
            controlId="pickUpPincode"
            label="Pickup Pincode"
            placeholder="PickUp Pincode"
            register={register}
            errors={errors}
          />
          <CustomDeliveryPincodeFormControl
            controlId="deliveryPincode"
            label="Delivery Pincode"
            placeholder="Delivery Pincode"
            register={register}
            errors={errors}
          />
          <PaymentModeControl
            controlId="paymentMode"
            label="Payment Mode"
            control={control}
            errors={errors}
            isCOD={true}
          />
          {watch('paymentMode') === 'COD' && (
            <CodAmountFormControl register={register} errors={errors} />
          )}
          <WeightFormControl
            register={register}
            errors={errors}
            control={control}
          />

          <LengthFormControl
            register={register}
            errors={errors}
            control={control}
          />
          <HeightFormControl
            register={register}
            errors={errors}
            control={control}
          />
          <WidthFormControl
            register={register}
            errors={errors}
            control={control}
          />
        </SimpleGrid>
        <Button
          mt={4}
          colorScheme="blue"
          isLoading={isSubmitting}
          type="submit"
        >
          Submit
        </Button>
        <Box>
          Volumetric Weight : {''}
          {(length && width && height) != null
            ? (length * width * height) / 5000
            : ''}
          kg or{' '}
          {(length && width && height) != null
            ? (length * width * height) / 5
            : ''}
          gram
        </Box>
      </form>
    </Flex>
  );
};
export default B2CcalculatorForm;
