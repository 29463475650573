import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  Text,
  FormErrorMessage,
  Select,
  Input,
  Spinner,
  Tag,
  Flex,
  Badge,
  Icon,
  IconButton,
  Button,
} from '@chakra-ui/react';
import { MdArrowDropDown, MdDelete } from 'react-icons/md';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { FaPlus, FaRupeeSign } from 'react-icons/fa';
import {useSelector} from 'react-redux';
import {getB2cConfig} from '../../redux/slices/SettingsDetailsSlice';
export const PaymentModeFormControl = ({ errors, control }) => {
  return (
    <FormControl isInvalid={errors.paymentMode}>
      <FormLabel htmlFor="paymentMode">
        Order Type
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        name="paymentMode"
        control={control}
        rules={{ required: 'This is required' }}
        render={({ field }) => (
          <RadioGroup {...field}>
            <Stack spacing={5} direction="row">
              <Radio colorScheme="blue" value="Prepaid">
                Prepaid
              </Radio>
              <Radio colorScheme="blue" value="COD">
                COD
              </Radio>
            </Stack>
          </RadioGroup>
        )}
      />
      <FormErrorMessage>{errors?.paymentMode?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const ServiceModeControl = ({
  controlId,
  label,
  errors,
  control,
  options,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        control={control}
        name="serviceMode"
        rules={{ required: 'This is required' }}
        render={({ field }) => (
          <Select placeholder="Select Service Mode" id={controlId} {...field}>
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      />
      {errors[controlId] && (
        <FormErrorMessage>{errors[controlId].message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export const ServiceProviderFormControl = ({
  errors,
  ServiceProviderList,
  control,
  onChange
}) => {
  return (
    <FormControl isInvalid={errors.serviceProvider}>
      <FormLabel htmlFor="serviceProvider">
        Service Provider
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        control={control}
        name="serviceProvider"
        rules={{ required: 'This is required' }}
        render={({ field }) => (
          <Select
            id="serviceProvider"
            placeholder="Select Service Provider"
            {...field}
            onChange={e => {
              field.onChange(e);
              onChange(e.target.value);
            }}
          >
           {Object.keys(ServiceProviderList).map((provider) => (
              <option key={provider} value={ServiceProviderList[provider].value}>{provider}
              </option>
            ))}
          </Select>
        )}
      />
      {errors.serviceProvider && (
        <FormErrorMessage>{errors.serviceProvider.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export const ServiceTypeFormControl = ({ errors, control,selServiceProvider }) => {
  const b2cConfig=useSelector(getB2cConfig);
  const {servicePartners}=b2cConfig;
  const serviceProvider = useWatch({ control, name: 'serviceProvider' });
  return (
    <FormControl isInvalid={errors.serviceType}>
      <FormLabel htmlFor="serviceType">
        Choose Service
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        control={control}
        name="serviceType"
        rules={{ required: 'This is required' }}
        render={({ field }) => (
          <Select
            id="serviceType"
            placeholder={'Select Service Type'}
            {...field}
          >
            {servicePartners[selServiceProvider]?.service.map(serviceType => (
              <option key={serviceType.value} value={serviceType.value}>
                {serviceType.label}
              </option>
            ))}
          </Select>
        )}
      />

      <FormErrorMessage>{errors?.serviceType?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const PickupPointFormControl = ({
  control,
  errors,
  pickupLocations,
  id,
  label
}) => {
  return (
    <FormControl isInvalid={errors[id]}>
      <FormLabel htmlFor={id}>
        {label} {" "}Address 
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        control={control}
        name={`${id}`}
        rules={{ required: "This is required" }}
        render={({ field }) => (
          <Select {...field} id={`${id}`} placeholder={`Select ${label} Address`}>
            {pickupLocations?.map((location) => (
              <option key={location?.addressId} value={location?.addressId}>
                {`${location.cpPerson}-${location.address1}-${location.pincode}`}
              </option>
            ))}
          </Select>
        )}
      />
      {errors[id] && (
        <FormErrorMessage>{errors[id].message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export const ContactNameFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.name}>
      <FormLabel htmlFor="name">
        Contact Name{' '}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="name"
        placeholder="Name"
        {...register('name', {
          required: 'This is required',
          minLength: {
            value: 4,
            message: 'Minimum length should be 4',
          },
          maxLength: {
            value: 50,
            message: 'Maximum length should be 50',
          },
        })}
      />
      <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const ContactMobileFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.mobileNumber}>
      <FormLabel htmlFor="mobileNumber">
        Contact Mobile
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="mobileNumber"
        placeholder="Mobile Number"
        {...register('mobileNumber', {
          required: 'This is required',
          pattern: {
            value: /^[6-9]\d{9}$/,
            message: 'Wrong mobile number',
          },
          maxLength: {
            value: 10,
            message: 'Maximum length should be 10',
          },
          minLength: {
            value: 10,
            message: 'Minimum length should be 10',
          },
        })}
      />
      <FormErrorMessage>{errors?.mobileNumber?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const AlternateMobileFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors?.alternateNumber}>
      <FormLabel htmlFor="alternateNumber">Alternate Mobile</FormLabel>
      <Input
        id="alternateNumber"
        placeholder="Alternate Mobile Number"
        {...register('alternateNumber', {
          pattern: {
            value: /^[6-9]\d{9}$/,
            message: 'Wrong mobile number',
          },
          maxLength: {
            value: 10,
            message: 'Maximum length should be 10',
          },
          minLength: {
            value: 10,
            message: 'Minimum length should be 10',
          },
        })}
      />
      <FormErrorMessage>{errors?.alternateNumber?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const EmailFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors?.email}>
      <FormLabel htmlFor="email">Email Id</FormLabel>
      <Input
        id="email"
        type="email"
        placeholder="abc@xyz.com"
        {...register('email', {
          pattern: {
            value: /[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
            message: 'Wrong email id',
          },
        })}
      />
      <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const AddressTypeFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors?.addressType}>
      <FormLabel htmlFor="addressType">Delivery Address Type</FormLabel>
      <Select
        id="addressType"
        placeholder="Select Address Type"
        {...register('addressType', {
          required: 'This is required',
        })}
      >
        <option value="Home">Home</option>
        <option value="Office">Office</option>
      </Select>
      <FormErrorMessage>{errors?.addressType?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const Address1FormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors?.address1}>
      <FormLabel htmlFor="address1">Address1</FormLabel>
      <Input
        id="address1"
        placeholder="Address1"
        {...register('address1', {
          required: 'This is required',
          minLength: {
            value: 4,
            message: 'Minimum length should be 4',
          },
          maxLength: {
            value: 100,
            message: 'Maximum length should be 100',
          },
        })}
      />
      <FormErrorMessage>{errors?.address1?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const Address2FormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors?.address2}>
      <FormLabel htmlFor="address2">Address2</FormLabel>
      <Input
        id="address2"
        placeholder="Address2"
        {...register('address2', {
          minLength: {
            value: 4,
            message: 'Minimum length should be 4',
          },
          maxLength: {
            value: 100,
            message: 'Maximum length should be 100',
          },
        })}
      />
      <FormErrorMessage>{errors?.address2?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const DeliveryPincodeFormControl = ({ control, errors }) => {
  return (
    <FormControl isInvalid={errors.pincode}>
      <FormLabel htmlFor="pincode">
        Pincode
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        control={control}
        name="pincode"
        rules={{
          required: 'This is required',
          pattern: {
            value: /^[1-9][0-9]{5}$/,
            message: 'Wrong pincode',
          },
        }}
        render={({ field }) => (
          <Input {...field} id="pincode" placeholder="Pincode" />
        )}
      />
      {errors.pincode && (
        <FormErrorMessage>{errors.pincode.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export const LandmarkFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.landmark}>
      <FormLabel htmlFor="landmark">Landmark</FormLabel>
      <Input
        id="landmark"
        placeholder="Landmark"
        {...register('landmark', {
          minLength: {
            value: 4,
            message: 'Minimum length should be 4',
          },
          maxLength: {
            value: 40,
            message: 'Maximum length should be 40',
          },
        })}
      />
      <FormErrorMessage>{errors?.landmark?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const CityFormControl = ({ register, errors, isPincodeDataLoading }) => {
  return (
    <FormControl isInvalid={errors.city}>
      <FormLabel htmlFor="city">
        City
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        isDisabled={!!isPincodeDataLoading}
        cursor={isPincodeDataLoading ? 'not-allowed' : 'auto'}
        _disabled={{ opacity: '0.8' }}
        id="city"
        placeholder="City"
        {...register('city', {
          required: 'This is required',
        })}
      />
      {isPincodeDataLoading ? <Spinner color="blue.500" /> : null}
      <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const StateFormControl = ({
  register,
  errors,
  isPincodeDataLoading,
}) => {
  return (
    <FormControl isInvalid={errors.state}>
      <FormLabel htmlFor="state">
        State
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        isDisabled={!!isPincodeDataLoading}
        cursor={isPincodeDataLoading ? 'not-allowed' : 'auto'}
        _disabled={{ opacity: '0.8' }}
        id="state"
        placeholder="State"
        {...register('state', { required: 'This is required' })}
      />
      {isPincodeDataLoading ? <Spinner color="blue.500" /> : null}
      <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const EwayBillFormControl = ({ register, errors, control }) => {
  const invoiceValue = useWatch({ control, name: 'invoice' });
  return (
    <FormControl isInvalid={errors.ewayBill}>
      <FormLabel htmlFor="ewayBill">E-Way Bill Number</FormLabel>
      <Input
        control={control}
        id="ewayBill"
        placeholder="Eway Bill Number"
        {...register('ewayBill', {
          required: Number(invoiceValue) > 50000 ? 'This is required' : false,
        })}
      />
      <FormErrorMessage>{errors?.ewayBill?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const SkuNumberFormControl = ({ register, errors, isRequired }) => {
  return (
    <FormControl isInvalid={errors.skuNumber}>
      <FormLabel htmlFor="skuNumber">
        SKU Number
        <Text as="span" color="red">
          {isRequired && '*'}
        </Text>
      </FormLabel>
      <Input
        id="skuNumber"
        type="text"
        placeholder="Enter SKU Number"
        {...register('skuNumber', {
          required: isRequired ? 'This is required' : false,
        })}
      />
      <FormErrorMessage>{errors?.skuNumber?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const OrderNumberFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.orderNumber}>
      <FormLabel htmlFor="orderNumber">
        Order Number
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="orderNumber"
        type="text"
        placeholder="Enter Order Number"
        {...register('orderNumber', {
          required: 'This is required',
        })}
      />
      <FormErrorMessage>{errors?.orderNumber?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const ProductNameFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.productName}>
      <FormLabel htmlFor="productName">
        Product Description
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="productName"
        placeholder="Enter Product Name"
        {...register('productName', {
          required: 'This is required',
          minLength: {
            value: 2,
            message: 'Minimum length should be 2',
          },
        })}
      />
      <FormErrorMessage>{errors?.productName?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const ProductTypeFormControl = ({ register, errors, ProductType }) => {
  return (
    <FormControl isInvalid={errors.productType}>
      <FormLabel htmlFor="productType">
        Product Type
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Select
        id="productType"
        placeholder="Select Product Type"
        {...register('productType', {
          required: 'This is required',
        })}
      >
        {ProductType.map(pType => (
          <option key={pType.value} value={pType.value}>
            {pType.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{errors?.productType?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const WeightFormControl = ({ register, errors, control }) => {
  return (
    <FormControl isInvalid={errors.weight}>
      <FormLabel htmlFor="Weight">
        Weight (kg)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        control={control}
        id="weight"
        type="number"
        step="any"
        placeholder="Weight (0.4 kg) = 400gm"
        {...register('weight', {
          required: 'This is required',
        })}
      />
      <FormErrorMessage>{errors?.weight?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const QuantityFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.quantity}>
      <FormLabel htmlFor="quantity">
        Quantity
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="quantity"
        placeholder="Enter Quantity"
        {...register('quantity', {
          required: 'This is required',
        })}
      />

      <FormErrorMessage>{errors?.quantity?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const LengthFormControl = ({ register, errors, control }) => {
  return (
    <FormControl isInvalid={errors.length}>
      <FormLabel htmlFor="length">
        Length (cm)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        control={control}
        id="length"
        type="number"
        step={'any'}
        placeholder="Enter Length "
        {...register('length', {
          required: 'This is required',
        })}
      />

      <FormErrorMessage>{errors?.length?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const WidthFormControl = ({ register, errors, control }) => {
  return (
    <FormControl isInvalid={errors.width}>
      <FormLabel htmlFor="width">
        Width (cm)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        control={control}
        id="width"
        type="number"
        step={'any'}
        placeholder="Enter Breadth "
        {...register('width', {
          required: 'This is required',
        })}
      />

      <FormErrorMessage>{errors?.width?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const HeightFormControl = ({ register, errors, control }) => {
  return (
    <FormControl isInvalid={errors.height}>
      <FormLabel htmlFor="height">
        Height (cm)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        control={control}
        id="height"
        type="number"
        step={'any'}
        placeholder="Enter Height "
        {...register('height', {
          required: 'This is required',
        })}
      />

      <FormErrorMessage>{errors?.height?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const InvoiceFormControl = ({ register, errors, control }) => {
  return (
    <FormControl isInvalid={errors.invoice}>
      <FormLabel htmlFor="invoice">
        Invoice Value
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="invoice"
        control={control}
        type="text"
        placeholder="Enter Invoice Value"
        {...register('invoice', {
          required: 'This is required',
        })}
      />
      <FormErrorMessage>{errors?.invoice?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const CodAmountFormControl = ({ register, errors, control }) => {
  return (
    <FormControl isInvalid={errors.codAmount}>
      <FormLabel htmlFor="codAmount">
        COD Amount
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        control={control}
        id="codAmount"
        type="number"
        placeholder="Enter COD Amount"
        {...register('codAmount', {
          required: 'This is required',
        })}
      />
      <FormErrorMessage>{errors?.codAmount?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const FormTag = ({ tagText, ...props }) => {
  return (
    <Flex justifyContent={'center'} my={3} {...props}>
      <Tag
        textAlign={'center'}
        colorScheme="blackAlpha"
        px={5}
        py={2}
        letterSpacing={1.5}
        fontSize={16}
      >
        {tagText}
      </Tag>
    </Flex>
  );
};

export const EWaybillNotification = () => {
  return (
    <Tag
      textAlign={'center'}
      colorScheme="blackAlpha"
      px={5}
      py={2}
      letterSpacing={1.5}
      fontSize={16}
    >
      <Text as={'span'} color={'blue.500'}>
        E-Waybill no. Mandatory&nbsp;
      </Text>{' '}
      Your total invoice amount is more than{' '}
      <Icon as={FaRupeeSign} boxSize={4} color="#474747" />
      50000.00
    </Tag>
  );
};

export const B2bInvoiceNumberFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.invoiceNumber}>
      <FormLabel htmlFor="invoiceNumber">
        Invoice Number
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="invoiceNumber"
        type="text"
        placeholder="Enter Invoice Number"
        {...register('invoiceNumber', {
          required: 'This is required',
        })}
      />
      <FormErrorMessage>{errors?.invoiceNumber?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const PriceServiceProviderName = ({ row }) => {
  return (
    <Text fontWeight={700} fontSize={15} mx={'auto'}>
      {row?.serviceProviderName}{' '}
      <Badge
        borderRadius="lg"
        ml={3}
        variant="subtle"
        colorScheme="green"
        fontSize={8}
        size={2}
      >
        {row?.serviceType?.slice(0, 3)}
      </Badge>
    </Text>
  );
};

export const PriceShipmentPartner = ({ row }) => {
  return (
    <Text fontWeight={700} fontSize={15} mx={'auto'}>
      {row?.TAT}
    </Text>
  );
};
export const PriceCharge = ({ row }) => {
  const Amount = row?.CodCharge
    ? Number(row?.CodCharge) + Number(row?.price)
    : Number(row?.price);
  return (
    <Text fontWeight={700} fontSize={15} mx={'auto'}>
      {`${Amount}`}
    </Text>
  );
};
export const B2bQuantityFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.quantity}>
      <FormLabel htmlFor="quantity">
        Quantity
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="quantity"
        type="number"
        placeholder="Enter Quantity "
        {...register('quantity', {
          required: 'This is required',
        })}
      />
      <FormErrorMessage>{errors?.quantity?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const B2BBookingDimensionsForm = ({
  register,
  control,
  errors,
  watch,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'volume',
  });
  const volume = watch('volume');
  const quantity = watch('quantity');
  const volumeLength = volume?.reduce(
    (acc, item) => acc + Number(item.count),
    0
  );

  return (
    <Flex
      flexDir={'column'}
      gap={4}
      border={'1px solid'}
      borderColor={'blackAlpha.200'}
      p={5}
      rounded={'md'}
    >
      <FormControl isInvalid={errors.unit}>
        <Select
          w={150}
          {...register('unit', {
            required: 'This is required',
          })}
          icon={<MdArrowDropDown />}
          textTransform={'capitalize'}
          fontWeight={'bold'}
          color={'#718096'}
        >
          {['cm', 'feet', 'inch'].map(mode => (
            <option
              style={{
                fontWeight: 'bold',
                color: '#718096',
              }}
              key={mode}
              value={mode}
            >
              {mode}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors?.unit?.message}</FormErrorMessage>
      </FormControl>
      {fields?.map((field, index, array) => (
        <Flex key={field.id} gap={4} direction={['column', 'row']}>
          <FormControl isInvalid={errors.volume?.[index]?.count}>
            <Input
              type="number"
              placeholder="Qty"
              _placeholder={{
                fontWeight: 'bold',
              }}
              {...register(`volume.${index}.count`, {
                required: 'This is required',
              })}
            />
            {quantity != volumeLength &&
            index === array.length - 1 &&
            quantity ? (
              <Text color="#8EBEEB" fontSize={12} pt={1}>
                {Number(quantity) - volumeLength} Package(s) left
              </Text>
            ) : (
              ''
            )}
            <FormErrorMessage>
              {errors.volume?.[index]?.count?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.volume?.[index]?.height}>
            <Input
              type="number"
              placeholder="Height"
              _placeholder={{
                fontWeight: 'bold',
              }}
              {...register(`volume.${index}.height`, {
                required: 'This is required',
              })}
            />
            <FormErrorMessage>
              {errors.volume?.[index]?.height?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.volume?.[index]?.length}>
            <Input
              type="number"
              placeholder="Length"
              _placeholder={{
                fontWeight: 'bold',
              }}
              {...register(`volume.${index}.length`, {
                required: 'This is required',
              })}
            />
            <FormErrorMessage>
              {errors.volume?.[index]?.length?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.volume?.[index]?.width}>
            <Input
              type="number"
              placeholder="Width"
              _placeholder={{
                fontWeight: 'bold',
              }}
              {...register(`volume.${index}.width`, {
                required: 'This is required',
              })}
            />
            <FormErrorMessage>
              {errors.volume?.[index]?.width?.message}
            </FormErrorMessage>
          </FormControl>
          {fields?.length !== 1 && (
            <IconButton
              icon={<MdDelete />}
              onClick={() => remove(index)}
              colorScheme="red"
              aria-label=""
            />
          )}
        </Flex>
      ))}
      <Button
        borderRadius={'sm'}
        w={150}
        mx={'auto'}
        colorScheme={'orange'}
        isDisabled={
          quantity === undefined || quantity <= 0 || quantity <= volumeLength
        }
        size={'sm'}
        leftIcon={<FaPlus />}
        onClick={() => append({ count: '', height: '', length: '', width: '' })}
      >
        Add More
      </Button>
    </Flex>
  );
};
